import {eventChannel} from "redux-saga";
import {fork, take, put, call, cancelled, select} from "redux-saga/effects";
import {CallType, OutgoingCallType, TerminationReason, UserAccountPermissions, UserRole} from "@sense-os/goalie-js";

import analyticsService from "../../analytics/AnalyticsService";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {createSendMessage, subscribeToInterWindowCommunication} from "../../IWC/IWC";
import {WinMsg, WinMsgTypes} from "../../IWC/WinMsg";
import createLogger from "../../logger/createLogger";
import {agoraActions} from "../redux/agoraActions";
import {AgoraWindowAction} from "../redux/types";
import {
	getAgoraRoomId,
	getAgoraWindowAction,
	getChannelId,
	getInvitationCreator,
	getRtcToken,
	isJoinWithVideo,
} from "../redux/agoraSelectors";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import {getAllContacts, getSelectedContactId} from "../../contacts/redux/contactSelectors";
import {getSelectedCallType, isVideoCall} from "../../startCallScreen/redux/startCallScreenSelector";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";
import {Contact} from "../../contacts/contactTypes";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {ChatRoomAction} from "../../chat/redux/ChatRoomAction";
import {callActions} from "../../call/redux/callActions";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {CallDirection} from "services/chat/video/CallDirection";
import {getActiveCall} from "redux/videoCall/VideoCallSelectors";
import {timeTrackingActions} from "../../timeTracking/redux/timeTrackingActions";
import {DISC} from "IoC/DISC";

const log = createLogger("agoraWindowMsgSaga", SentryTags.AgoraMeeting);

function* createIncomingAgoraWindowMsgChannel(targetWindow: Window) {
	return eventChannel((emitter) => {
		const unsubscribe = subscribeToInterWindowCommunication({
			targetWindow,
			onMessage: emitter,
		});

		return unsubscribe;
	});
}

/**
 * This function will be executed when call window is ready to receive IWC messages from the main window.
 * // TODO Remove when Agora SDK implementation is done.
 */
function* syncAgoraWindowData(agoraWindow: Window) {
	log.debug("syncAgoraWindowData");

	const sendIWCMessage = yield call(createSendMessage, agoraWindow);
	const authUser: AuthUser = yield select(getAuthUser);
	const selectedContactId: number = yield select(getSelectedContactId);
	const windowAction: AgoraWindowAction = yield select(getAgoraWindowAction);
	const roomId: string = yield select(getAgoraRoomId);
	const isVideoEnabled: boolean = yield select(isVideoCall);
	const isJoinAgoraWithVideo: boolean = yield select(isJoinWithVideo);

	// Send analytics instance id to the call window so that we can initialize the analyticsService in the call window with the same ID.
	yield call(sendIWCMessage, {
		type: WinMsgTypes.SHARE_PARENT_ANALYTICS_ID,
		data: {
			parentInstanceID: analyticsService.getInstanceID(),
			agoraWindowAction: windowAction,
			roomId,
			authUser,
			remoteUserId: selectedContactId,
			isVideoEnabled: isJoinAgoraWithVideo || isVideoEnabled,
		},
	});
}

/**
 * This function will be executed when agorar channel window is ready to receive IWC messages from the main window.
 */
function* syncAgoraChannelWindowData(agoraWindow: Window) {
	log.debug("syncAgoraChannelWindowData");

	const sendIWCMessage = yield call(createSendMessage, agoraWindow);
	const authUser: AuthUser = yield select(getAuthUser);
	const isVideoEnabled: boolean = yield select(isVideoCall);
	const channelId: string = yield select(getChannelId);
	const rtcToken: string = yield select(getRtcToken);
	const contacts: Contact[] = yield select(getAllContacts);
	const isJoinAgoraWithVideo: boolean = yield select(isJoinWithVideo);
	const channelCreatorId: number = yield select(getInvitationCreator);
	const outgoingCallType: OutgoingCallType = yield select(getSelectedCallType);

	// Send analytics instance id to the call window so that we can initialize the analyticsService in the call window with the same ID.
	yield call(sendIWCMessage, {
		type: WinMsgTypes.SHARE_PARENT_ANALYTICS_ID,
		data: {
			parentInstanceID: analyticsService.getInstanceID(),
			channelId,
			rtcToken,
			authUser,
			isVideoEnabled: isJoinAgoraWithVideo || isVideoEnabled,
			contacts,
			channelCreatorId,
			outgoingCallType,
		},
	});
}

function* incomingWindowMsgHandler(agoraWindow: Window, msg: WinMsg) {
	log.debug("incoming iwc data", msg);

	const windowAction: AgoraWindowAction = yield select(getAgoraWindowAction);
	const authUser: AuthUser = yield select(getAuthUser);
	const isTherapist: boolean = authUser.roles.some((role) => role === UserAccountPermissions.THERAPIST);

	switch (msg.type) {
		case WinMsgTypes.AGORA_WINDOW_READY:
			// TODO Remove when Agora SDK implementation is done.
			// Synchronize Agora window data from the main window by sending them via IWC
			yield fork(syncAgoraWindowData, agoraWindow);
			break;

		case WinMsgTypes.AGORA_CHANNEL_WINDOW_READY:
			// Synchronize Agora channel window data from the main window by sending them via IWC
			yield fork(syncAgoraChannelWindowData, agoraWindow);
			break;

		case WinMsgTypes.PRODUCT_FRUITS_CHECK:
			// Check for product fruits data props
			yield call(sendProductFruitsProps, agoraWindow);
			break;

		case WinMsgTypes.AGORA_CREATED_MEETING_LINK:
			if (windowAction === AgoraWindowAction.CREATE) {
				// TODO remove this condition after reconstruct agora app builder messaging event
				const createdLink: string = msg.data?.value?.link.toString();
				yield put(agoraActions.userCreatedMeeting(createdLink));
			}
			break;

		case WinMsgTypes.AGORA_JOIN_MEETING:
			const joinedTime: number = Date.now();
			yield put(agoraActions.userJoinedMeeting(joinedTime));
			// Add joined time to local storage for countinous analytics purpose
			storage.write(StorageKeys.AGORA_JOINED_MEETING, {joinedTime: joinedTime});

			if (isTherapist) {
				// Create initial `activeCall` then store it to redux
				const initialActiveCall: ActiveCall = {
					roomId: msg.data.channelId,
					initiatorUserId: msg.data.channelCreatorId,
					type: msg.data.isVideoCall ? CallType.Video : CallType.Audio,
					direction:
						authUser.id === msg.data.channelCreatorId ? CallDirection.OUTGOING : CallDirection.INCOMING,
					isConferenceCall: true,
					participantMap: {
						[authUser.id]: {
							initiatedTime: joinedTime,
							joinedTime: joinedTime,
							leaveTime: joinedTime,
							firstName: authUser.firstName,
							lastName: authUser.lastName,
							profilePicture: authUser.imageUrl,
							publicId: authUser.hashId,
							role: UserRole.THERAPIST,
							organization: {
								id: authUser?.organization?.id,
								name: authUser?.organization?.name,
								logo: authUser?.organization?.logo,
							},
						},
					},
				};
				yield put(callActions.createActiveCall(initialActiveCall));
			}

			break;

		case WinMsgTypes.AGORA_LEAVE_MEETING: {
			const currentActiveCall: ActiveCall = yield select(getActiveCall);
			const leaveTime: number = Date.now();

			if (isTherapist && currentActiveCall) {
				// Update existing active call data with local user actual `leaveTime`
				const updatedActiveCall: ActiveCall = {
					...currentActiveCall,
					participantMap: {
						...currentActiveCall.participantMap,
						[authUser.id]: {
							...currentActiveCall.participantMap[authUser.id],
							leaveTime: leaveTime,
						},
					},
				};

				// Create auto time tracking for ended agora meeting
				yield put(
					timeTrackingActions.createTimeEntryForCallSession(updatedActiveCall, msg.data.outgoingCallType),
				);
			}

			// Send call summary
			// FIXME: sending call summary still doesn't work
			yield call(DISC.getVideoCallService().sendCallSummary, TerminationReason.NormalHangUp);

			yield put(agoraActions.userLeaveMeeting(leaveTime));
			// Simply close the Agora window when user leave meeting
			yield put(agoraActions.closeAgoraWindow());
			break;
		}

		case WinMsgTypes.AGORA_REMOTE_USER_JOINED: {
			// Called when remote user `joined` the meeting
			const currentActiveCall: ActiveCall = yield select(getActiveCall);

			if (isTherapist && currentActiveCall) {
				const newParticipantMap = Object.assign(currentActiveCall.participantMap, msg.data.participantMap);
				const updatedActiveCall: ActiveCall = {
					...currentActiveCall,
					participantMap: newParticipantMap,
				};

				// Update the active call
				yield put(callActions.createActiveCall(updatedActiveCall));
			}

			break;
		}

		case WinMsgTypes.AGORA_REMOTE_USER_LEFT: {
			// Called when remote user `left` the meeting
			const currentActiveCall: ActiveCall = yield select(getActiveCall);
			const leaveUserId = msg.data.leaveUserId;
			const leaveTime = msg.data.leaveTime;
			const leaveReason = msg.data.leaveReason;

			if (isTherapist && currentActiveCall) {
				const updatedActiveCall: ActiveCall = {
					...currentActiveCall,
					participantMap: {
						...currentActiveCall.participantMap,
						[leaveUserId]: {
							...currentActiveCall.participantMap[leaveUserId],
							leaveTime: leaveTime,
						},
					},
				};

				// Update the active call
				yield put(callActions.createActiveCall(updatedActiveCall));
			}

			// Send call summary
			// FIXME: sending call summary still doesn't work
			yield call(DISC.getVideoCallService().sendCallSummary, leaveReason, leaveUserId);

			break;
		}

		case WinMsgTypes.AGORA_INVITE_OTHER_USER:
			// Send invitation through chat
			const messageText: string = loc.formatMessage(strTranslation.AGORA.notification.invitation.text, {
				name: authUser.fullName,
			});

			yield put(
				ChatRoomAction.sendTextMessage(msg.data.userId, messageText, {
					meetingInfo: {
						id: msg.data.channelId,
						callType: msg.data.isVideoCall ? CallType.Video : CallType.Audio,
					},
				}),
			);
			break;

		case WinMsgTypes.AGORA_ERROR:
			// Send error to Sentry
			log.captureException(msg.data?.value, {message: "Agora app builder error"});
			break;
	}
}

export function* onIncomingWindowMsgSaga(agoraWindow: Window) {
	const incomingCallWindowMsgChannel = yield call(createIncomingAgoraWindowMsgChannel, agoraWindow);
	try {
		while (true) {
			const msg: WinMsg = yield take(incomingCallWindowMsgChannel);
			yield fork(incomingWindowMsgHandler, agoraWindow, msg);
		}
	} finally {
		if (yield cancelled()) {
			incomingCallWindowMsgChannel.close();
		}
	}
}

/**
 * This function will send data for product fruits props
 */
function* sendProductFruitsProps(callWindow: Window) {
	log.debug("sendProductFruitsProps");

	const authUser = yield select(getAuthUser);
	const sendIWCMessage = yield call(createSendMessage, callWindow);

	// Send authUser data to call window
	yield call(sendIWCMessage, {
		type: WinMsgTypes.PRODUCT_FRUITS_CHECK,
		data: authUser,
	});
}
