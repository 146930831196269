import {eventChannel} from "redux-saga";
import {cancel, fork, put, takeEvery, call, takeLeading, take, takeLatest, select} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import _ from "lodash";

import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {WinMsgTypes} from "../../IWC/WinMsg";
import {createSendMessage} from "../../IWC/IWC";
import {toastActions} from "../../toaster/redux";
import {
	restoreChatPresenceToOnline,
	setChatPresenceToBusyIfOnline,
} from "../../call/sagas/callWindow/updateChatPresence";
import {sidebarNavActions} from "../../sidebarNav/redux/sidebarNavActions";
import {BrowserEvents} from "constants/BrowserEvents";
import {isSafariBrowser} from "../../browserWarning/helpers/browserWarningUtils";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {getAuthUser} from "../../auth/redux";
import {AuthUser} from "../../auth/authTypes";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";

import {agoraActions} from "../redux/agoraActions";
import {createAgoraWindow, getAgoraWindow, resetAgoraWindow} from "../window/agoraWindowHelpers";
import {onIncomingWindowMsgSaga} from "./agoraWindowMsgSaga";
import {clearMainWindowOnCloseChannel, onMainWindowClosedSaga} from "./mainWindowSaga";
import {ChatRoomAction} from "../../chat/redux/ChatRoomAction";
import {getSelectedContactId} from "../../contacts/redux/contactSelectors";
import {EMPTY_MESSAGE} from "../../fileSharing/fileSharingTypes";
import {isInvitationDialogOpen} from "../redux/agoraSelectors";
import {AgoraChannel} from "@sense-os/goalie-js";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {agoraSDK} from "../agoraSDK/agoraSdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import featureFlags from "../../featureFlags/FeatureFlags";
import {privateNotesActions} from "../../privateNotes/redux/privateNotesAction";

const log = createLogger("agoraWindowSaga", SentryTags.AgoraMeeting);

function* initAgoraWindow(agoraWindow: Window) {
	log.debug("initializing Agora window");

	// Subscribe to call window IWC messages
	const incomingWindowMsgTask = yield fork(onIncomingWindowMsgSaga, agoraWindow);

	// Wait for Agora window to be closed
	yield call(onAgoraWindowClosedSaga, agoraWindow);

	// Stop any running saga in `onIncomingWindowMsgSaga`
	yield cancel(incomingWindowMsgTask);
}

function* openAgoraWindowSaga(action: ActionType<typeof agoraActions.openAgoraWindow.request>) {
	try {
		const agoraWindow: Window = yield call(createAgoraWindow);
		const isInvitationDialogOpened: boolean = yield select(isInvitationDialogOpen);

		// Check if Agora window is opened or not
		if (!agoraWindow || agoraWindow.closed) {
			// Popup is blocked, inform the user to troubleshoot
			yield put(
				toastActions.addToast({message: "CHAT.video.popup_blocker.toast", type: "error", timeout: 30000}),
			);
			throw new Error("Unable to open Agora window");
		} else {
			const {channelId} = action.payload;

			if (featureFlags.meetingAgoraSdk && !!channelId) {
				const token: string = yield call(getSessionId);

				// Get rtc token from created channel
				const response: AgoraChannel = yield apiCallSaga(agoraSDK.getChannelById, token, channelId);
				yield put(agoraActions.setRtcToken(response.rtcToken));

				yield put(privateNotesActions.startListeningToIwc(agoraWindow));
			}

			yield put(agoraActions.openAgoraWindow.success());
			yield put(sidebarNavActions.collapseSidebar());

			if (isInvitationDialogOpened) {
				yield put(agoraActions.closeInvitationDialog());
			}
		}

		// Initialize features that need to communicate with the agora window via IWC
		const sendIWCMessage = yield call(createSendMessage, agoraWindow);

		// Initialize listeners
		yield fork(initAgoraWindow, agoraWindow);
		yield fork(onMainWindowClosedSaga, agoraWindow);

		// Ping Agora window to check whether Agora window is ready to recieve data from main window.
		// The Agora window will send WinMsgTypes.AGORA_CHECK_WINDOW if it is.
		yield call(sendIWCMessage, {type: WinMsgTypes.AGORA_CHECK_WINDOW});

		// Change online presence to BUSY
		yield call(setChatPresenceToBusyIfOnline);
	} catch (err) {
		log.captureException(err, {message: "Unable to open Agora window."});
		yield put(agoraActions.openAgoraWindow.failure(err));
	}
}

function* closeAgoraWindowSaga() {
	const agoraWindow: Window = yield call(getAgoraWindow);
	if (agoraWindow && agoraWindow.open) {
		// Simply close Agora window.
		// This should trigger "onclose" event, which is handled in `onAgoraWindowClosedSaga`
		agoraWindow.close();
	}
}

function* onAgoraWindowClosedSaga(agoraWindow: Window) {
	const agoraWindowCloseChannel = yield call(createCallWindowOnCloseChannel, agoraWindow);

	yield take(agoraWindowCloseChannel);
	yield call(onAgoraWindowClosedHandler);

	agoraWindowCloseChannel.close();
}

/**
 * Removes all listeners
 */
function* onAgoraWindowClosedHandler() {
	log.addBreadcrumb({message: "Agora window closed"});
	// Restore chat presence from BUSY to ONLINE
	yield call(restoreChatPresenceToOnline);

	// Reset call window instance
	yield call(resetAgoraWindow);

	// Reset main window "onclose" event
	yield call(clearMainWindowOnCloseChannel);

	// Bring back the sidebar
	yield put(sidebarNavActions.expandSidebar());

	// Reset Agora window state
	yield put(agoraActions.resetAgoraWindowState());

	// Reset private note IWC
	yield put(privateNotesActions.stopListeningToIwc());
}

function* createCallWindowOnCloseChannel(callWindow: Window) {
	/**
	 * Somehow on Safari browsers, the `BrowserEvents.BEFORE_UNLOAD` window event will be exectued
	 * after the event is registered. This is a hacky solution for preventing safari to close Agora window without any reason.
	 *
	 * TODO: Find another solution beside this hacky solution
	 */
	const isSafari = yield call(isSafariBrowser);
	let canEmitEvent = !isSafari;

	return eventChannel((emit) => {
		callWindow.addEventListener(
			BrowserEvents.BEFORE_UNLOAD,
			(evt) => {
				if (canEmitEvent) {
					log.addBreadcrumb({
						message: "Agora window is closed via beforeunload event, emitting event channel",
					});
					evt.stopPropagation();
					emit(evt);
				} else {
					canEmitEvent = true;
				}
			},
			{once: true},
		);

		// Gradually check if Agora window is still opened or not.
		// This is a hack approach to ensure that we can end an ongoing call in mobile devices.
		// It's because the unreliability of `beforeunload`, `unload`, and `pagehide` window event
		// where it won't always be triggered when window is closed.
		// See https://developers.google.com/web/updates/2018/07/page-lifecycle-api
		// See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
		const intervalId = window.setInterval(() => {
			if (callWindow.closed) {
				log.addBreadcrumb({message: "Agora window is closed via interval checks, emitting event channel"});
				emit({});
			}
		}, 1000);

		return () => {
			window.clearInterval(intervalId);
		};
	});
}

/**
 * Send created meeting link as chat text message
 */
function* sendCreatedMeetingLink(action: ActionType<typeof agoraActions.userCreatedMeeting>) {
	const selectedContactId: number = yield select(getSelectedContactId);
	const authUser: AuthUser = yield select(getAuthUser);
	const createdLink: string = action.payload.createdLink;

	// TODO Remove encoding and newlines after Backend support Agora link convertion for mobile apps push notification
	const encodedLink: string = encodeURIComponent(createdLink);
	const newLines: string = _.repeat("\n", 30); // Create 30 new lines

	/**
	 * Replace invitation link only with Agora invitation text template
	 *
	 * Example:
	 * <therapist_name> has started a call.
	 *
	 * https://meeting.agora....
	 */
	const messageText =
		`${loc.formatMessage(strTranslation.AGORA.notification.invitation.text, {
			name: authUser.fullName,
		})}` +
		newLines +
		encodedLink;

	yield put(ChatRoomAction.sendTextMessage(selectedContactId, messageText || EMPTY_MESSAGE, {}));
}

/**
 * Remove joned time from local storage
 */
function* clearAgoraLocalStorage() {
	storage.remove(StorageKeys.AGORA_JOINED_MEETING);
}

export default function* () {
	yield takeLeading(getType(agoraActions.openAgoraWindow.request), openAgoraWindowSaga);
	yield takeLeading(getType(agoraActions.openAgoraWindow.failure), closeAgoraWindowSaga);
	yield takeEvery(getType(agoraActions.closeAgoraWindow), closeAgoraWindowSaga);
	yield takeLatest(getType(agoraActions.userCreatedMeeting), sendCreatedMeetingLink);
	yield takeEvery(getType(agoraActions.userLeaveMeeting), clearAgoraLocalStorage);
}
